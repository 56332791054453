import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FundService } from '@state/fund';

@Injectable({ providedIn: 'root' })
export class FundRedirectGuard  {
  constructor(private fundService: FundService, private router: Router) {}

  canActivate(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable(() => {
      let fund_id: string = routeSnapshot.params.fund_id;

      this.fundService.get$(fund_id).subscribe({
        error: this.handleError.bind(this),
        next: (fund) => {
          void this.router.navigate(['/e/', fund.context.id, 'fund', fund_id]);
        }
      });
    });
  }

  handleError(errorResponse: Response): void {
    if (errorResponse.status === 404) {
      void this.router.navigate(['/404'], { skipLocationChange: true });
    } else {
      console.error(errorResponse);
    }
  }
}
