import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';

import { dateWithoutTimeCondensed } from '@helpers/date';
import { getFlatNumber, isDollarAmount, isPercentAmount, normalize, toKebabCase } from '@helpers/string';

import {
  ActionType,
  BulkSelections,
  Column,
  ColumnLike,
  ColumnType,
  RowSettings
} from './collection-table.model';

export function defaultSortingDataAccessor<T>(obj: T, key: string): string | number {
  let value: string | number | string[] = obj[key];

  if (typeof value === 'string') {
    if (isDollarAmount(value) || isPercentAmount(value)) return getFlatNumber(value);
    return normalize(value);
  }

  if (typeof value === 'number') {
    if (isFinite(value)) return Number(value);
    return value.toString();
  }

  if (key === 'tags' && value) return value.join('').toLowerCase();

  return;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-collection-table',
  styleUrls: ['./collection-table.component.scss'],
  templateUrl: './collection-table.component.html'
})
export class CollectionTableComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @HostListener('document:keydown.shift')
  setShiftHeldOn(): void {
    this.shiftHeld = true;
  }

  @HostListener('document:keyup.shift')
  setShiftHeldOff(): void {
    this.shiftHeld = false;
  }

  // Applies border color + bulk select checkbox when true
  @Input() bulkSelectable: (obj: any) => boolean = () => false;
  @Input() bulkSelections: BulkSelections<any>;
  @Input() columnSettings: ColumnLike[];
  @Input() compact: boolean;
  @Input() constrained: boolean;
  @Input() customSort: (obj: any, key: string) => any;
  @Input() dataSourceData: any[];
  @Input() matSortActive: string;
  @Input() matSortDirection: 'asc' | 'desc';
  @Input() remoteMode = false;
  @Input() rowSettings: RowSettings;
  // Applies border color when true
  @Input() selectable: boolean;
  @Input() showFooter: boolean;
  // Applies to header, footer, and max table height
  @Input() sticky: boolean;
  @Input() unpadded: boolean;

  @Output() bulkSelectionsChange = new EventEmitter<BulkSelections<any>>();

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort)
  set dataSourceSort(sort: MatSort) {
    let sortHandler: (sortChange?: MatSort) => void;
    if (sort) {
      if (!this.remoteMode) {
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.dataSource.sort = sort;
        sortHandler = this.resetBulkSelections;
      } else {
        sortHandler = this.navigateOnSort;
      }
      if (!this.sortChangeSubscription) {
        this.sortChangeSubscription = sort.sortChange.subscribe({ next: sortHandler });
      }
    }
  }

  ActionType = ActionType;
  bulkLastSelected: { i: number; checked: boolean };
  columns: ColumnLike[] = [];
  dataSource = new MatTableDataSource([]);
  dateWithoutTimeCondensed = dateWithoutTimeCondensed;
  isBulkSelectableCountZero: boolean;
  nullComparison = (): number => null;
  shiftHeld = false;
  sortChangeSubscription: Subscription;
  toKebabCase = toKebabCase;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    if (this.constrained) this.sticky = true;
  }

  ngOnChanges(changes) {
    // Guard is required because angular also fires changes for
    // certain render and element state events
    if (changes.columnSettings) {
      // Sets bulk column when enabled
      if (this.bulkSelections) {
        changes.columnSettings.currentValue.unshift({ def: 'bulk', type: ColumnType.Bulk });
      }

      this.columns = changes.columnSettings.currentValue;

      // Work around for an internal angular issue
      // https://github.com/angular/components/issues/13030
      this.table.removeFooterRowDef(null);
    }

    if (changes.dataSourceData) {
      if (this.bulkSelections) this.bulkSelections = {};
      // This needs to be thrown out of the regular angular lifecycle
      setTimeout(() => this.bulkSelectionsChange.emit(this.bulkSelections));

      this.dataSource.data = changes.dataSourceData.currentValue;
    }

    // Outer condition is required to reduce the amount of rerenders, we only want to perform a render
    // when either dataSourceData or columnSettings have changed
    if (changes.dataSourceData || changes.columnSettings) {
      let dataSourceData = changes.dataSourceData?.currentValue || this.dataSource.data;
      let columnSettings = changes.columnSettings?.currentValue || this.columns;
      // This needs to be run at the end of the current stack, but only if both
      // dataSourceData and columnSettings are present
      if (dataSourceData && columnSettings) setTimeout(() => this.table.renderRows());
    }
  }

  ngOnDestroy(): void {
    if (this.sortChangeSubscription) this.sortChangeSubscription.unsubscribe();
  }

  dataTestId = ({ column, i }: { column: Column; i: string }): string => column.testId?.concat('-lp-', i);

  resetBulkSelections = (): void => {
    if (!this.bulkSelections) return;
    this.bulkSelections = {};
    this.bulkSelectionsChange.emit(this.bulkSelections);
  };

  navigateOnSort = (sortChange: MatSort): void => {
    void this.router.navigate([], {
      queryParams: { sort_by: sortChange.active, sort_direction: sortChange.direction },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      state: {
        scrollPosition: this.viewportScroller.getScrollPosition()
      }
    });
  };

  get bulkAllSelected(): boolean {
    let bulkSelectableCount = this.dataSource.data.filter((obj) => this.bulkSelectable(obj)).length;
    if (this.isSelectableCountZero(bulkSelectableCount)) return;
    return bulkSelectableCount && bulkSelectableCount === this.bulkSelectionsCount;
  }

  get bulkSelectionsCount(): number {
    return Object.keys(this.bulkSelections).length;
  }

  get bulkSomeSelected(): boolean {
    return !!this.bulkSelectionsCount && !this.bulkAllSelected;
  }

  get displayedColumns(): string[] {
    if (!this.columns) return;
    return this.columns.map((col) => col.def);
  }

  get sortingDataAccessor(): (obj: any, key: string) => any {
    return this.customSort || this.defaultSortingDataAccessor;
  }

  defaultSortingDataAccessor = (obj: any, key: string): number | string =>
    defaultSortingDataAccessor(obj, key);

  checkToggle(event: MouseEvent, i: number): void {
    // Need to skip standard select / animation for bulk selections
    // to reflect actual status
    if (this.shiftHeld) {
      event.preventDefault();

      if (!this.bulkSelections) return;
      let [a, b] = [this.bulkLastSelected.i, i].sort((c, d) => c - d);
      this.toggleFrom(this.bulkLastSelected.checked, a, b + 1);
    }
  }

  isSelectableCountZero(bulkSelectableCount: number): boolean {
    this.isBulkSelectableCountZero = bulkSelectableCount === 0 && this.bulkSelectionsCount === 0;
    return this.isBulkSelectableCountZero;
  }

  toggleAll(checked: boolean): void {
    if (checked) {
      this.toggleFrom(checked, 0, this.dataSource.data.length);
    } else {
      this.resetBulkSelections();
    }
  }

  toggleFrom(checked: boolean, start: number, finish: number): void {
    let activeSelections = { ...this.bulkSelections };
    let sortedData = this.dataSource.connect().value;

    for (let i = start; i < finish; i++) {
      if (checked) {
        let obj = sortedData[i];
        if (this.bulkSelectable(obj)) activeSelections[i] = obj;
      } else {
        delete activeSelections[i];
      }
    }

    this.bulkSelections = activeSelections;
    this.bulkSelectionsChange.emit(this.bulkSelections);
  }

  updateBulkSelections(checked: boolean, i: number, row?: any): void {
    if (checked) {
      this.bulkSelections[i] = row;
    } else {
      delete this.bulkSelections[i];
    }

    this.bulkLastSelected = { checked, i };
    this.bulkSelectionsChange.emit(this.bulkSelections);
  }
}
