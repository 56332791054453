import { addEntities, withActiveId, withEntities } from '@ngneat/elf-entities';
import { createStore } from '@ngneat/elf';
import { Injectable } from '@angular/core';

import { Entity } from './entity.model';

@Injectable({ providedIn: 'root' })
export class EntitiesRepository {
  private _store$ = createStore(
    { name: 'entities' },
    withActiveId(),
    withEntities<Entity>()
  );

  addEntity(entity: Entity): void {
    this._store$.update(addEntities(entity));
  }

  reset(): void {
    this._store$.reset();
  }
}
