import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

import { Entity } from '@state/entities';
import { ID } from '@state/base';

interface MembershipEntityProps {
  membershipEntity?: Entity;
}

let initialState: MembershipEntityProps = {};

@Injectable({ providedIn: 'root' })
export class MembershipEntityRepository {
  private _store$ = createStore({ name: 'investor' }, withProps<MembershipEntityProps>(initialState));

  membershipEntity$ = this._store$.pipe(select(({ membershipEntity }) => membershipEntity));

  getMembershipEntity(): Entity {
    return this._store$.getValue().membershipEntity;
  }

  getOrganizationID(): ID {
    return this._store$.getValue().membershipEntity?.organization_id;
  }

  hasOrganizationContacts(): boolean {
    return this._store$.getValue().membershipEntity?.organization_contacts_count > 0;
  }

  setMembershipEntity(entity: Entity): void {
    this._store$.update(setProp('membershipEntity', entity));
  }

  reset(): void {
    this._store$.reset();
  }
}
