import { createStore, select, withProps } from '@ngneat/elf';
import { filter } from 'rxjs';
import { Injectable } from '@angular/core';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

// This rule is to facilitate the case where we have no active flags used on the client
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LaunchDarklyProps {
  'ldf-enable-communication-preferences'?: boolean;
  'ldf-enable-interest-notification'?: boolean;
  'ldf-enable-lp-read-only-permissions'?: boolean;
  'ldf-enable-reports-for-providers'?: boolean;
  'ldf-enable-universal-link'?: boolean;
  'ldf-questionnaire-review'?: boolean;
  'ldf-vehicle-data-report'?: boolean;
  ready: boolean;
}

export interface LaunchDarklyChangeset {
  [key: string]: { current: any; previous: any };
}

export const launchDarklyStore$ = createStore(
  { name: 'launchDarkly' },
  withProps<LaunchDarklyProps>({ ready: false })
);

@Injectable({ providedIn: 'root' })
export class LaunchDarklyRepository {
  enableCommunicationPreferences$ = launchDarklyStore$.pipe(
    select((state) => !!state['ldf-enable-communication-preferences'])
  );
  enableInterestNotification$ = launchDarklyStore$.pipe(
    select((state) => !!state['ldf-enable-interest-notification'])
  );
  enableQuestionnaireReview$ = launchDarklyStore$.pipe(
    select((state) => !!state['ldf-questionnaire-review'])
  );
  enableReportsForProviders$ = launchDarklyStore$.pipe(
    select((state) => !!state['ldf-enable-reports-for-providers'])
  );
  enableUniversalLink$ = launchDarklyStore$.pipe(select((state) => !!state['ldf-enable-universal-link']));
  enableVehicleDataReport$ = launchDarklyStore$.pipe(select((state) => !!state['ldf-vehicle-data-report']));
  ready$ = launchDarklyStore$.pipe(
    filter((state) => state.ready),
    select((state) => state.ready)
  );

  getEnableCommunicationPreferences(): boolean {
    return launchDarklyStore$.getValue()['ldf-enable-communication-preferences'];
  }

  getQuestionnaireReview(): boolean {
    return launchDarklyStore$.getValue()['ldf-questionnaire-review'];
  }

  getVehicleDataReport(): boolean {
    return launchDarklyStore$.getValue()['ldf-vehicle-data-report'];
  }

  set(flags: LDFlagSet): void {
    launchDarklyStore$.update(() => ({ ready: true, ...flags }));
  }

  setReady(ready: boolean): void {
    launchDarklyStore$.update((state) => ({ ...state, ready }));
  }

  update(flags: LaunchDarklyChangeset | LDFlagSet): void {
    let updatedFlags = {};

    for (let [key, values] of Object.entries(flags)) {
      updatedFlags[key] = values.current || values;
    }

    launchDarklyStore$.update((state) => ({ ...state, ...updatedFlags }));
  }
}
