import { BaseModel, ID } from '@state/base';
import { EntityContact } from '@state/contact';
import { EntityAddress, PrimaryContact } from '@state/entities';
import { InvestorState as InvestmentState } from '@state/investment';
import { TaskActorLite } from '@state/task';

export enum InvestorTaskType {
  SignSubscription = 'sign_subscription'
}

export interface ActionableFormJoinActor {
  user: {
    full_name?: string;
  };
}
export interface ActionableFormJoinDocument extends BaseModel {
  thumbnail?: string;
  title: string;
}

export interface ActionableFormJoin extends BaseModel {
  assignable_id: ID;
  assignable_type: string;
  complete_actors: ActionableFormJoinActor[];
  document?: ActionableFormJoinDocument;
  done_actors: [];
  executed_document?: ActionableFormJoinDocument;
  form_id: ID;
  form_rtd: boolean;
  form_title: string;
  state: string;
}

export interface Investor {
  accreditation_type?: string;
  carry_percent_formatted?: string;
  contributed_amount_formatted?: string;
  contributed_at?: string;
  contributed_at_formatted?: string;
  created_at_formatted: string;
  created_at_millis: number;
  created_at: string;
  customer_code?: string;
  display_name: string;
  effective_date?: string;
  expense_reserve?: string;
  final: string;
  id: ID;
  investment_account_id?: string;
  investment_account_entity_id?: string;
  investor_email: string;
  investor_name: string;
  investor_type?: string;
  invitation_email?: string;
  invitation_id?: string;
  invitation_message?: string;
  invitation_name?: string;
  invitation_sent_at?: string;
  legal_name?: string;
  life_distributions?: number;
  management_fee_percent_formatted: string;
  net_asset_value?: number;
  notes?: string;
  ownership?: string;
  ownership_percent_formatted?: string;
  ownership_units_formatted?: string;
  primary_contact: PrimaryContact;
  security_share_price: number;
  security_share_price_formatted?: string;
  state?: InvestmentState;
  tax_id?: string;
  total_due_formatted?: string;
  total_received?: string;
  total_received_formatted?: string;
  type_display_label?: string;
  updated_at_formatted: string;
  unfunded_commitment_amount?: string;
  unfunded_commitment_amount_formatted?: string;
  updated_at_millis: number;
  updated_at: string;
  wired: boolean;
}

export interface InvestorInvestmentAccountMember {
  created_at: string;
  created_at_formatted: string;
  created_at_millis: number;
  id: ID;
  initials: string;
  name: string;
  updated_at: string;
  updated_at_formatted: string;
  updated_at_millis: number;
}

export interface ProviderConnection {
  active: boolean;
  client_id: ID;
  id: ID;
  permission: string;
  provider_id: ID;
  role: string;
}

export interface InvestorInvestmentAccount {
  accreditation_type?: string;
  address: EntityAddress;
  contact_email?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_title?: string;
  created_at: string;
  created_at_formatted: string;
  created_at_millis: number;
  display_name: string;
  entity?: EntityContact;
  entity_id: ID;
  entity_subtype_formatted?: string;
  entity_type_formatted?: string;
  id: ID;
  legal_name?: string;
  provider_connection?: ProviderConnection;
  tax_id_last_four?: string;
  tax_id_type?: string;
  trust_subtype_formatted?: string;
  trust_type_formatted?: string;
  type_display_label: string;
  type_formatted?: string;
  updated_at: string;
  updated_at_formatted: string;
  updated_at_millis: number;
}

export interface InvestorInvestmentAccountListItem {
  created_at: string;
  created_at_formatted: string;
  created_at_millis: number;
  display_name?: string;
  entity_id: ID;
  entity_subtype_formatted?: string;
  entity_type_formatted: string;
  id: ID;
  legal_name: string;
  members: InvestorInvestmentAccountMember[];
  trust_subtype_formatted?: string;
  trust_type_formatted?: string;
  type_display_label: string;
  type_formatted: string;
  updated_at: string;
  updated_at_formatted: string;
  updated_at_millis: number;
}

export interface InvestorDocument {
  created_at_formatted: string;
  created_at_millis: number;
  created_at: string;
  document_folder_id?: ID;
  id: ID;
  logo: string;
  organization_name: string;
  owner_legal_name: string;
  page_count_formatted: string;
  page_count: number;
  preview?: string;
  tags: string[];
  thumbnail: string;
  title: string;
  updated_at_formatted: string;
  updated_at_millis: number;
  updated_at: string;
  vehicle_name: string;
  vehicle_organization_name: string;
}

export interface InvestorTask extends BaseModel {
  actionable_forms_joins?: ActionableFormJoin[];
  active: boolean;
  completed_at?: string;
  completed_at_formatted?: string;
  completed_by_name?: string;
  form_roles?: string[];
  gp_action?: InvestorGPAction;
  locked: boolean;
  pending_verification: boolean;
  signers?: TaskActorLite[];
  signature_request_id?: ID;
  started_at?: string;
  task_name: string;
  title: string;
  type: InvestorTaskType;
  verified_at_formatted?: string;
  verified_by_name?: string;
}

export interface InvestorGPAction {
  actor: InvestorGpActionActor;
  name: string;
  initials: string;
  message: string;
  action: InvestorGPActionAction;
  actionable: InvestorGPActionActionable;
}

export interface InvestorGpActionActor {
  initials: string;
  name: string;
}

export interface InvestorGPActionAction {
  cta: string;
  url: string;
  type: 'api' | 'application';
}

export interface InvestorGPActionActionable {
  logo: string;
  label: string;
}
