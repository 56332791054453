import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { DragulaModule } from 'ng2-dragula';
import { environment } from '@env/environment';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { JwtModule } from '@auth0/angular-jwt';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';

import { AnyModule } from '@pipe/any';
import { BaseInterceptor } from '@app/interceptor/base.interceptor';
import { BlankLayoutModule } from '@app/layout/blank-layout';
import { FlowDialogsModule } from '@app/shared/components/dialogs/flow-dialogs.module';
import { ForgotPasswordModule } from '@component/forgot-password/forgot-password.module';
import { FourOhFourModule } from '@app/route/four-oh-four';
import { GoogleAnalyticsService } from '@app/shared/services/vendor';
import { HasAnyModule } from '@pipe/has-any';
import { NoneModule } from '@pipe/none/none.module';
import { PipesModule } from '@app/shared/pipes/pipes.modules';
import { ResetPasswordModule } from '@component/reset-password/reset-password.module';
import { rollbarFactory, RollbarErrorHandler, RollbarService } from '@state/rollbar';
import { VariableService } from '@app/shared/services';
import { ZendeskConfig, ZendeskModule } from '@state/zendesk';

import { DocumentsAppComponent } from './documents-app.component';
import { DocumentsAppRoutingModule } from './documents-app-routing.module';
import { FlowMaterialModule } from './flow-material.module';

function getCookie(name: string): string {
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieSegments = decodedCookie.split(/;/);

  for (let cookieSegment of cookieSegments) {
    let [cookieName, cookieValue] = cookieSegment.trim().split('=');
    if (cookieName === name) return cookieValue;
  }

  return '';
}

// ------------------------------------------------------------------------------
// Module Definition
@NgModule({
  bootstrap: [DocumentsAppComponent],
  declarations: [DocumentsAppComponent],
  exports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlowDialogsModule,
    FlowMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  imports: [
    AnyModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DocumentsAppRoutingModule,
    DragulaModule.forRoot(),
    FlowDialogsModule,
    FlowMaterialModule,
    ForgotPasswordModule,
    BlankLayoutModule,
    FormsModule,
    FourOhFourModule,
    HasAnyModule,
    MatSnackBarModule,
    HttpClientModule,
    InlineSVGModule,
    // JWT will technically intercept HTTP calls, but we can do multiple interceptors
    JwtModule.forRoot({
      config: {
        allowedDomains: environment.JWT_WHITELIST_DOMAINS,
        tokenGetter: () => getCookie('token')
      }
    }),
    NoneModule,
    PipesModule.forRoot(),
    ReactiveFormsModule,
    ResetPasswordModule,
    ZendeskModule.forRoot(ZendeskConfig)
  ],
  providers: [
    CookieService,
    VariableService,
    GoogleAnalyticsService,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: BaseInterceptor
    },
    {
      provide: ErrorHandler,
      useClass: environment.runtimeEnv === 'development' ? ErrorHandler : RollbarErrorHandler
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        disableClose: true
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000, horizontalPosition: 'right', verticalPosition: 'top' }
    },
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false
    },
    { provide: RollbarService, useFactory: rollbarFactory }
  ]
})
export class DocumentsAppModule {}
