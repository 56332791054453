import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { OrganizationService, PublicInfo } from '@state/organization';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class PublicInfoResolver {
  constructor(
    private router: Router,
    private organizationService: OrganizationService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PublicInfo> {
    let urlSlug: string = route.params.organization_slug;
    return this.organizationService.getAndStorePublicInfo$(urlSlug).pipe(
      catchError(() => {
        void this.router.navigate(['/404'], { skipLocationChange: true });
        return of();
      })
    );
  }
}
